<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title
        >Configuración para Ejecutar el Proceso Calidad
        {{ proceso.nombre }}</v-card-title
      >
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <div
                v-if="loadingArchivos"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingArchivos"
                v-model="archivo"
                :items="archivos"
                :item-text="'id_nombre'"
                :item-value="'id'"
                attach
                :label="'Archivo del Layout Principal:'+proceso.layout.nombre"
                :rules="required"
                return-object
              ></v-select>
              <div v-if="debug">
                   {{archivo}}
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12">
              <div class="text-center" v-if="!pasos.length">
                <div class="spinner-border" role="status"></div>
              </div>
              <v-data-table
                v-if="pasos.length"
                :headers="headers"
                :items="relacionFuenteArchivo"
                :items-per-page="-1"
                class="elevation-1"
                hide-default-footer
              >
                
                <template v-slot:item.id_cargue="props">
                  <div
                    v-if="!props.item.archivos.length"
                    class="spinner-border"
                    role="status"
                  ></div>
                  <v-select
                    v-if="props.item.archivos.length"
                    v-model="props.item.archivo"
                    :items="props.item.archivos"
                    :item-text="'id_nombre'"
                    :item-value="'id'"
                    attach
                    :label="
                      'Seleccionar Archivo'
                    "
                    :rules="required"
                    return-object
                  ></v-select>
                  <div v-if="debug">
                  {{props.item.archivo}}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

        </v-container>
      </v-form>
      <v-divider></v-divider>
      <!-- <pre>{{proceso}}</pre> -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingEjecutar" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingEjecutar"
          color="primary"
          class="px-4"
          @click="ejecutarAlpha2()"
          :disabled="!valid"
          >Ejecutar</CButton
        >
      </v-card-actions>
    </v-card>
    <v-switch v-model="debug" :label="`Mostrar metadata de archivos`"></v-switch>
    <v-btn
     @click="asignarRelacionFuenteArchivo()"
      elevation="2"
    >Actualizar Json</v-btn>
    
    <pre>
      {{ pasos }}
    </pre>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EjecucionProcesoCalidadService from "@/services/ejecucionProcesoCalidad.service";
import ArchivoService from "@/services/archivo.service";
import PasoProcesoCalidadService from "@/services/pasoProcesoCalidad.service";
import validationField from "@/util/validationField";
import LayoutService from "@/services/layout.service";
import ListaService from "@/services/lista.service";
import { mapState } from "vuex";

export default {
  name: "Calidad",

  data() {
    return {
      proceso: {
        nombre: undefined,
        layout_id: undefined,
        tipo_operacion_id: undefined,
        periodicidad_ejecucion_id: undefined,
        rol_ejecutor_id: undefined,
        rol_consulta_id: undefined,
        descripcion: undefined,
        activo: true,
        layout:[],
      },
      debug: false,
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      headers: [
        { text: "Fuentes Encontradas en el Proceso", value: "fuente_nombre", sortable: false },
        { text: "Seleccionar Archivo de la Fuente", value: "id_cargue", sortable: false },
      ],
      archivo: undefined,
      archivoSecundario: undefined,
      archivos: [],
      relacionFuenteArchivo: [],
      pasos: [],
      fuentes: [],
      loading: false,
      loadingArchivos: false,
      loadingLayouts: false,
      loadingTiposOperacion: false,
      loadingPeriodicidades: false,
      loadingRoles: false,
      loadingEjecutar: false,
      message: "",
      dialog: false,
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  watch: {},
  mounted() {
    if (!this.permissions.includes("Definición de proceso de calidad"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_proceso_calidad")) {
      this.proceso = JSON.parse(
        localStorage.getItem("datos_proceso_calidad")
      );
      this.fetchArchivos("");
      this.fetchPasos("");
    }

    // this.fetchLayouts("");
  },
  methods: {
    cargarRelacionFuenteArchivo() {
      let _this = this
      this.pasos.forEach(function(paso, index) {
        _this.nodoPaso (JSON.parse(paso.expresion))
      });

      this.relacionFuenteArchivo.forEach(function(obj, index) {
        _this.fetchArchivosFuentes(index)
      });
    },
    nodoPaso (paso) {
      let _this = this
      if (paso.tipo == 'opl') 
        paso.nodos.forEach(function(nodo, index) {
          _this.nodoPaso(nodo)
        });
      else
	if(paso.regla!= undefined && paso.regla != null)
        	this.revisarRegla(JSON.parse(paso.regla.expresion))
    },
    revisarRegla(regla) {
      let _this = this
      if (regla.tipo == 'opl')
        regla.nodos.forEach(function(nodo, index) {
          _this.revisarRegla(nodo)
        });
      else {
        // this.agregarRelacionFuenteArchivo( {fuente_id: regla.fuente.id, fuente_nombre: regla.fuente.nombre, archivo: { id: regla.variable.archivo_id }, archivos: [] } )
        this.agregarRelacionFuenteArchivo( {fuente_id: regla.fuente.id, fuente_nombre: regla.fuente.nombre, archivo: null, archivos: [] } )
        if (regla.fuente_comparacion)
          this.agregarRelacionFuenteArchivo( {fuente_id: regla.fuente_comparacion.id, fuente_nombre: regla.fuente_comparacion.nombre, archivo: null, archivos: [] } )
      }    
    },
    agregarRelacionFuenteArchivo(obj) {
      if (!this.relacionFuenteArchivo.find(x => x.fuente_id == obj.fuente_id))
        this.relacionFuenteArchivo.push(obj)
    },
    asignarRelacionFuenteArchivo() {
      console.log("asignarRelacionFuenteArchivo")
      console.log(this.pasos.length)
      
      for (let p = 0; p < this.pasos.length; p++) {
        console.log(p)
        this.pasos[p].expresion = JSON.stringify( this.asignarNodoPaso(JSON.parse(this.pasos[p].expresion)) )
      }
    },
    asignarNodoPaso (paso) {
      if (paso.tipo == 'opl') 
        for (let n = 0; n < paso.nodos.length; n++) {
          paso.nodos[n] = this.asignarNodoPaso(paso.nodos[n])
        }
      else
	if(paso.regla!= undefined && paso.regla!=null)
        	paso.regla.expresion = JSON.stringify( this.asignarEnRegla(JSON.parse(paso.regla.expresion)) )
      return paso
    },
    asignarEnRegla(regla) {
      if (regla.tipo == 'opl')
        for (let n = 0; n < regla.nodos.length; n++) {
          regla.nodos[n] = this.asignarEnRegla(regla.nodos[n])
        }
      else {
        // console.log("Susituyendo el archivo de la fuente " + regla.fuente.nombre)
        console.log("asignarEnRegla")
	
        let x = this.relacionFuenteArchivo.find(x => x.fuente_id == regla.fuente.id)
        console.log(x)
        if(x.archivo != null && regla.variable != undefined){
	    console.log("x.archivo")
            console.log(x.archivo)
	    console.log(regla.variable)
            regla.variable.archivo = x.archivo.ruta
            regla.variable.archivo_id = x.archivo.id
        }else{
	    console.log("regla.variable:")
            console.log(regla.variable)
    	    if(regla.variable != undefined )
               regla.variable.archivo = [];
        }
        console.log("regla.fuente_comparacion")
        if (regla.fuente_comparacion && regla.constante_comparacion==undefined) {
          // console.log("relacionFuenteArchivo::::::")
          // console.table(this.relacionFuenteArchivo)
          // console.log("Regla:::::")
          // console.log(regla)
          let x = this.relacionFuenteArchivo.find(x => x.fuente_id == regla.fuente_comparacion.id)
          if(x.archivo != null){
            regla.variable_comparacion.archivo = x.archivo.ruta
            regla.variable_comparacion.archivo_id = x.archivo.id
          }else{
            regla.variable_comparacion.archivo= null
            regla.variable_comparacion.archivo_id = null
	        }
        }
      }
      return regla  
    },
    ejecutar() {
      console.log("asignarRelacionFA")
      this.asignarRelacionFuenteArchivo()
      console.log(this.archivo)
      this.loadingEjecutar = true;
      console.log(this.archivo)
      EjecucionProcesoCalidadService.ejecutar(this.proceso, { archivo: this.archivo, pasos: this.pasos }).then(
        (response) => {
          console.log(response.data);
          this.loadingEjecutar = false;
          this.$router.push({ path: `/calidad/procesos-calidad/ejecuciones` });
        },
        (error) => {
          this.content =
            ( error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingEjecutar = false;
          this.$router.push({ path: `/calidad/procesos-calidad/ejecuciones` });
        }
      );
    },
    ejecutarAlpha2() {
      this.asignarRelacionFuenteArchivo()
      this.loadingEjecutar = true;
      EjecucionProcesoCalidadService.ejecutarAlpha2(this.proceso, { archivo: this.archivo, pasos: this.pasos }).then(
        (response) => {
          console.log(response.data);
          this.loadingEjecutar = false;
          this.$router.push({ path: `/calidad/procesos-calidad/ejecuciones` });
        },
        (error) => {
          this.content =
            ( error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingEjecutar = false;
          this.$router.push({ path: `/calidad/procesos-calidad/ejecuciones` });
        }
      );
    },
    fetchArchivos(cadenaBusqueda) {
      this.loadingArchivos = true;
      console.log(this.proceso);
      ArchivoService.getItems(this.proceso.layout_id, cadenaBusqueda).then(
        (response) => {
          this.archivos = response.data;
          this.archivos = this.archivos.map(function f(x) {
            return { ...x, id_nombre: x.id + " - " + x.nombre };
          });
          console.log(response.data);
          this.loadingArchivos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingArchivos = false;
        }
      );
    },
    fetchArchivosFuentes(index) {
      console.log(this.proceso);
      ArchivoService.getItems(this.relacionFuenteArchivo[index].fuente_id, '').then(
        (response) => {
          this.relacionFuenteArchivo[index].archivos = response.data;
          this.relacionFuenteArchivo[index].archivos = this.relacionFuenteArchivo[index].archivos.map(function f(x) {
            return { ...x, id_nombre: x.id + " - " + x.nombre };
          });
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingArchivos = false;
        }
      );
    },
    fetchPasos(cadenaBusqueda) {
      this.loadingPasos = true;
      PasoProcesoCalidadService.getItems(this.proceso.id, cadenaBusqueda).then(
        (response) => {
          this.pasos = response.data;
          // this.items = this.items.map(function f(x) {
          //   return { ...x, layout_nombre: x.layout.nombre };
          // });
          console.log(response.data);
          this.loadingPasos = false;
          console.log("Impresión recursiva::::")
          this.cargarRelacionFuenteArchivo("")
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingPasos = false;
        }
      );
    },
    fetchLayouts(cadenaBusqueda) {
      this.loadingLayouts = true;
      LayoutService.getItems(cadenaBusqueda).then(
        (response) => {
          this.fuentes = response.data;
          this.loadingLayouts = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingLayouts = false;
        }
      );
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/calidad/procesos-calidad" });
    },
  },
};
</script>

<style>
</style>
